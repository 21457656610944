import service from "../util/link"
import url from "../util/apiurl"

/**
 * 充值
 */
export function payfor() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.payfor,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function confirmpay(obj) {
    return new Promise((resolve, reject) => {
       
        service.request({
            url: url.confirmpay,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function payloop(obj) {
  return new Promise((resolve, reject) => {
      service.request({
          url: url.payloop,
          method: "get",
          params: {
              order: obj
          }
      }).then((ok) => {
          resolve(ok)
      }).catch((err) => {
          reject(err)
      })
  })
}