<template>
  <div class="map">
    <el-row :gutter="0" class="search" type="flex" align="middle">
      <el-col :span="24"
        ><el-button type="primary" @click="paylist()"
          >点击充值</el-button
        ></el-col
      >
    </el-row>
    <el-table
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      tooltip-effect="dark"
      :data="tableData"
      border
      align="center"
      stripe
      style="width: 96%; margin-left: 2%; margin-top: 10px; color: #161718"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="iccid" label="卡编号" align="center" width="200">
      </el-table-column>
      <el-table-column
        prop="cost"
        label="续费一年单价"
        align="center"
        width="cost"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.cost }}元/年</span>
        </template>
      </el-table-column>
      <el-table-column prop="dataUsage" label="用量" align="center">
        <template slot-scope="scope">
          <span> {{ scope.row.dataUsage }}MB</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalDataVolume" label="总量" align="center">
        <template slot-scope="scope">
          <span> {{ scope.row.totalDataVolume }}MB</span>
        </template>
      </el-table-column>
      <el-table-column prop="usedDataVolume" label="当月用量" align="center">
        <template slot-scope="scope">
          <span> {{ scope.row.usedDataVolume }}MB</span>
        </template>
      </el-table-column>
      <el-table-column prop="iratePlanName" label="套餐规格" align="center">
        <!-- <template slot-scope="scope">
          <span v-if="scope.row.plan == 1">
            <el-radio v-model="radio" label="1">1年/500元</el-radio>
            <el-radio v-model="radio" label="2">2年/1000元</el-radio>
          </span>
        </template> -->
      </el-table-column>
      <el-table-column prop="startDate" label="激活时间" align="center">
      </el-table-column>

      <el-table-column prop="expireDate" label="到期时间" align="center">
      </el-table-column>
      <el-table-column prop="dataReminder" label="剩余" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.dataReminder > 30">
            {{ scope.row.dataReminder }}MB</span
          >
          <span v-else style="color: red"> {{ scope.row.dataReminder }}MB</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="plan" label="充值套餐" align="center" width="300">
        <template slot-scope="scope">
          <el-radio-group v-model="scope.row.plan" @change="selectradio()">
            <el-radio :label="1">1年/500元</el-radio>
            <el-radio :label="2">2年/1000元</el-radio>
          </el-radio-group>
        </template>
      </el-table-column> -->
      <el-table-column prop="expireDate" label="充值方式" align="center">
        <img src="../../../public/img/wechat.png" alt="" />
      </el-table-column>
    </el-table>
    <!-- <el-row class="paging" align="middle" type="flex" justify="center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev,total, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row> -->
    <el-dialog
      title="确认充值"
      :visible.sync="paytable"
      @closed="confirmclose"
      center
    >
      <el-table :data="confirmdata">
        <el-table-column property="iccid" label="卡编号"></el-table-column>
        <el-table-column
          property="iratePlanName"
          label="套餐类型"
        ></el-table-column>
        <el-table-column property="cost" label="续费一年单价">
          <template slot-scope="scope">
            <span>{{ scope.row.cost }}元/年</span>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="plan" label="充值套餐" align="center">
          <template slot-scope="scope">
            <span v-if="(scope.row.plan = '1')"> 1年/500元 </span>
            <span v-else> 2年/1000元 </span>
          </template>
        </el-table-column> -->
        <el-table-column prop="expireDate" label="支付方式" align="center">
          <img src="../../../public/img/wechat.png" alt="" />
        </el-table-column>
      </el-table>
      <el-row class="totalnum" type="flex" justify="space-between">
        <el-col :span="4"
          ><span style="font-weight: bold">充值规格：</span></el-col
        >
        <el-col :span="10"
          ><el-radio-group v-model="selectvalue" @change="selectradio">
            <el-radio :label="1">1年</el-radio>
            <el-radio :label="2">2年</el-radio>
          </el-radio-group></el-col
        >
        <el-col :span="6"
          ><span style="font-weight: bold">合计：</span><span>{{ total }}</span
          ><span>元</span></el-col
        >
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit()">确认充值</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="请打开微信扫码支付"
      :visible.sync="imgshow"
      center
      width="400px"
      @closed="payclose"
    >
      <div class="wxpayimg">
        <img :src="imgsrc" alt="" srcset="" style="width: 100%" />
        <div>
          <span>订单号：</span><span>{{ order }}</span>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <span>{{ paytext }}</span>
        <span
          @click="submit()"
          style="color: blue; cursor: pointer"
          v-if="refresh == true"
        >
          刷新
        </span>
        <span v-else>({{ time }}s)</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { payfor, confirmpay, payloop } from "../../api/payfor";
export default {
  data() {
    return {
      refresh: true,
      timer2: null,
      time: 5,
      selectvalue: 1,
      tableData: [],
      multipleSelection: [],
      checked: true,
      paytable: false,
      imgshow: false,
      confirmdata: [],
      submitlist: [],
      imgsrc: require("../../assets/img/payloading.gif"),
      total: "",
      order: "",
      paytext: "",
      timer: null,
      package: '1年',
    };
  },
  mounted() {
    this.getpayinfo();
  },
  methods: {
    getpayinfo() {
      payfor()
        .then((res) => {
          console.log(res);
          console.log("充值");
          // this.tableData = res.data.data;
          for (let i = 0; i < res.data.data.length; i++) {
            this.tableData.push({
              cost: res.data.data[i].cost,
              dataReminder: res.data.data[i].dataReminder,
              dataUsage: res.data.data[i].dataUsage,
              expireDate: res.data.data[i].expireDate,
              iccid: res.data.data[i].iccid,
              iratePlanName: res.data.data[i].iratePlanName,
              startDate: res.data.data[i].startDate,
              totalDataVolume: res.data.data[i].totalDataVolume,
              usedDataVolume: res.data.data[i].usedDataVolume,
              plan: "",
            });
          }
        })
        .catch((err) => {});
    },
    selectradio(e) {
      console.log(e, "选择套餐");

      if (e == 2) {
        this.total = e * this.total;
        this.package = "2年";
      }
      if (e == 1) {
        this.package = "1年";
        let arr = [];
        for (let i = 0; i < this.confirmdata.length; i++) {
          arr.push(this.confirmdata[i].cost);
        }
        let sum = arr.reduce((pre, cur) => {
          return pre + cur;
        });
        this.total = sum;
      }
    },
    // getcost( param){
    //   const { columns, data } = param;
    // 	const sums = [];
    // 	columns.forEach((column, index) => {
    // 		 if (index === 0) {
    // 		   sums[index] = '合计';
    // 		   return;
    // 		 }
    // 		 const values = data.map(item => Number(item[column.property]));
    // 		     if (column.property === 'cost' ) {
    // 					sums[index] = values.reduce((prev, curr) => {
    // 					 const value = Number(curr);
    // 					 if (!isNaN(value)) {
    // 					   return prev + curr;
    // 					 } else {
    // 					   return prev;
    // 					 }
    // 					}, 0);
    // 					sums[index];

    // 		     }
    // 		});
    // 		return sums

    // },
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    paylist() {
      console.log(this.multipleSelection, "11111");
      if (this.multipleSelection.length > 0) {
        this.paytable = true;
        this.confirmdata = this.multipleSelection;
        let arr = [];
        for (let i = 0; i < this.confirmdata.length; i++) {
          arr.push(this.confirmdata[i].cost);
        }
        let sum = arr.reduce((pre, cur) => {
          return pre + cur;
        });
        this.total = sum;
        this.multipleSelection.forEach((e) => {
          this.submitlist.push(e.iccid);
        });
      } else {
        this.$message({
          type: "warning",
          message: "请选择所要充值的设备",
        });
      }
    },
    submit() {
      console.log(this.submitlist);
      console.log(this.total, "钱");
      let total = this.total * 100;
      var param = new URLSearchParams();
      param.append("iccids", this.submitlist);
      param.append("total", total);
      // param.append("total", "1");
      param.append("package", this.package);
      confirmpay(param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data);
            console.log(res.data.qrcode_img);

            this.imgshow = true;

            this.imgsrc = `https://www.jass1688.com/${res.data.qrcode_img}`;
            this.order = res.data.order;
            this.paytext = "";
            this.refresh = true;
            this.paystatus();
          } else if (res.data.code == 5032) {
            this.imgshow = true;
            this.paytext = "订单生成失败请重试！";
          } else if (res.data.code == 5034) {
            this.imgshow = true;
            this.paytext = "二维码生成失败请重试！";
          }
        })
        .catch((e) => {});
    },

    paystatus() {
      this.timer = setInterval(() => {
        payloop(this.order)
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res.data, "pay返回");
              if (res.data.status == "SUCCESS") {
                this.refresh = false;
                clearInterval(this.timer);
                this.paytext = "支付成功！5s后关闭支付页面";
                this.imgsrc = require("../../../public/img/paysuccess.png");
                this.timer2 = setInterval(() => {
                  this.time--;
                  if (this.time === 0) {
                    clearInterval(this.timer2);
                    this.imgshow = false;
                    this.paytable = false;
                    this.tableData = [];
                    this.$refs.multipleTable.clearSelection();
                    this.getpayinfo();
                    this.imgsrc = require("../../assets/img/payloading.gif");
                  }
                }, 1000);
                // this.imgshow = false;
                // this.paytable = false;
                // this.tableData = [];
                // this.getpayinfo();
              } else if (res.data.status == "FAIL") {
                clearInterval(this.timer);
                this.paytext = "支付失败，请刷新二维码！";
              }
            } else {
            }
          })
          .catch((e) => {});

        // clearInterval(this.timer);
      }, 1500);
    },
    confirmclose() {
      this.$refs.multipleTable.clearSelection();
      this.submitlist.length=0
    },
    payclose() {
      clearInterval(this.timer);
      this.multipleSelection = [];
      this.imgshow = false;
      this.imgsrc = require("../../assets/img/payloading.gif");
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.paging {
width: 70%;
  float: right;
  margin-top: 0.625rem;
  margin-right: 10%;
}
.search {
  /* background-color: #EBEFF5; */
  text-align: start;
  /* border-radius: 0.625rem; */
  margin-top: 0.625rem;
  margin-left: 2%;
  color: #161718;
}
.wxpayimg {
  width: 23.125rem;
  height: 24.375rem;
  /* background-color: cadetblue; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.totalnum {
  margin-top: 0.9375rem;
}
.successimg {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>